//state lerin bailangıç değeri
const initialState = {
    sliderList: [],
    slider: [],
    newSlider: [],
}

//reducer iki parametre alır
//action da tanımlanan type a göre veri gönderir
// - state de yukarıda tanımlananları içerisinde tutar
const Reducer = (state = initialState, action ) => {
    switch (action.type) {
        case 'GET_ALL_SLIDER':
            return{
                ...state,
                sliderList: action.sliderList,
            };
        case 'GET_SLIDER':
            return{
                ...state,
                slider: action.slider,
            }            
        default:
            return state
    }
}

export default Reducer