const initialState = {
  historyList: [],
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_HISTORY":
      return {
        ...state,
        historyList: action.historyList,
      };
    default:
      return state;
  }
};

export default Reducer;
