const initialState = {
  productList: [],
  productDetail: [],
  foaProductList: [],
  productImageIds: [],
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_PRODUCT":
      return {
        ...state,
        productList: action.productList,
      };
    case "GET_PRODUCT":
      return {
        ...state,
        productDetail: action.productDetail,
      };
    case "GET_PRODUCT_BY_FOAID":
      return {
        ...state,
        foaProductList: action.foaProductList,
      };
    case "GET_PRODUCT_IMAGE_IDS":
      return {
        ...state,
        productImageIds: action.productImageIds,
      };

    default:
      return state;
  }
};

export default Reducer;
