const initialState = {
    applicantList: [],
    applicantForm:[]
}

const JobApplicantReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_JOB_APPLICANT':
            return { ...state, applicantList: action.applicantList }
        case 'JOB_APP_FORM':
            return { ...state, applicantForm: action.applicantForm }
        default:
            return state
    }
}

export default JobApplicantReducer