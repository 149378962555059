const initialState = {
    positionList: [],
    positionDetail: [],
    newPosition: [],
};

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ALL_POSITIONS":
            return {
                ...state,
                positionList: action.positionList,
            };
        case "GET_POSITION":
            return {
                ...state,
                positionDetail: action.positionDetail,
            };
        default:
            return state;
    }
};

export default Reducer;