const initialState = {
    usersDataList: [],
    usersDataDetailList: [],
    newUserData: [],
    claimsDataList: [],
}

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_USERS_LIST':
            return {
                ...state,
                usersDataList: action.usersDataList
            };
        case 'GET_CLAIMS_LIST': {
            return {
                ...state,
                claimsDataList: action.claimsDataList
            };
        }
        default:
            return state;
    }
};

export default Reducer;