const initialState = {
    mayorList: [],
    mayorDetail: [],
    newMayor: [],
};

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ALL_MAYOR":
            return {
                ...state,
                mayorList: action.mayorList,
            };
        case "GET_MAYOR":
            return {
                ...state,
                mayorDetail: action.mayorDetail
            };
        default:
            return state;
    }
};

export default Reducer;

