const initialState={
    ImageListData:[]
}

const CertificateReducer=(state=initialState,action)=>{
        switch(action.type){
            case 'GET_IMAGE_LIST':
                return{...state,ImageListData:action.alldata}
            default:
                return state
        }
}

export default CertificateReducer