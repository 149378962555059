const initialState = {
    SpecialDayList: [],
    SpecialDay: {}
}

const SpecialDayReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_SPECIALDAY_LIST':
            return { ...state, SpecialDayList: action.alldata }
        case 'GET_SPECIALDAY':
            return { ...state, SpecialDay: action.data }
        case 'GET_SPECIAL_DAY_ADMIN_BY_ID':
            return  { ...state, SpecialDay: action.data };
        default:
            return state
    }
}

export default SpecialDayReducer