const initialState = {
    reportList: [],
    coverImage: [],
    imageIds: [],
    galeryImage: [],
    getNewsById: [],
};

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ALL_REPORT":
            return {
                ...state,
                reportList: action.reportList,
            };

        case "GET_NEWS_COVER_IMAGE":
            return {
                ...state,
                coverImage: action.coverImage,
            };
        case "GET_IMAGE_IDS":
            return {
                ...state,
                imageIds: action.imageIds,
            };
        case "GET_NEWS_GALLERY_IMAGE":
            return {
                ...state,
                galeryImage: action.galeryImage,
            };
        case "GET_NEWS_ID":
            return {
                ...state,
                getNewsById: action.getNewsById,
            };
        default:
            return state;
    }
};

export default Reducer;

