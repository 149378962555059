const initialState = {
  foaList: [],
  foaDetail: [],
  foaImgIds: []
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_FOA":
      return {
        ...state,
        foaList: action.foaList,
      };
    case "GET_FOA":
      return {
        ...state,
        foaDetail: action.foaDetail,
      };
    case "GET_FOA_IMG_IDS":
      return {
        ...state,
        foaImgIds: action.foaImgIds,
      };
    default:
      return state;
  }
};

export default Reducer;
