const initialState = {
    departmentList: [],
    departmentDetail: [],
    newDepartment: [],
};

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ALL_DEPARTMENT":
            return {
                ...state,
                departmentList: action.departmentList,
            };
        case "GET_DEPARTMENT":
            return {
                ...state,
                departmentDetail: action.departmentDetail,
            };
        default:
            return state
    }
};

export default Reducer;