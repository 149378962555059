// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import slider from './slider'
import mayors from './mayor'
import OrgSchema from './OrgSchema'
import Departments from './Departments'
import Positions from './Positions'
import foa from './foa'
import Product from './foaproduct'
import Report from './Report'
import Contact from './Contact'
import About from './About'
import history from './history'
import certificate from '../Certificate/reducer'
import popup from '../Popup/reducer'
import jobApplicant from '../JobApplicant/reducer'
import usersList from './usersList'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  slider,
  mayors,
  OrgSchema,
  Departments,
  Positions,
  foa,
  Product,
  Report,
  Contact,
  About,
  history,
  certificate,
  popup,
  jobApplicant,
  usersList,
})

export default rootReducer
