const initialState = {
    contact: [],

};

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ALL_CONTACT":
            return {
                ...state,
                contact: action.contact,
            };

        default:
            return state;
    }
};

export default Reducer;
