const initialState = {
    about: {},

};

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ALL_ABOUT":
            return {
                ...state,
                about: action.about,
            };

        default:
            return state;
    }
};

export default Reducer;

